<template>
	<div id="container" style="padding:.5rem">
		<h1>{{getlang('用户服务协议和隐私政策',langs)}}</h1>
	
		<p class="text_indent">{{getlang('《用户服务协议和隐私政策》（以下简称“协议”）及其条款，系您下载、安装及使用',langs)}}<b>“Avatrade5”</b>{{getlang('（简称：Avatrade5）软件（以下简称“本软件”）所订立的、描述您与本软件之间权利义务的协议。',langs)}}</p>
	
		<p class="text_indent">{{getlang('在注册前务必认真阅读本协议的内容、充分理解各条款内容，如有异议，您可选择不进入本软件。一旦您确认本用户注册协议后，本协议即在您和本软件之间产生法律效力，意味着您完全同意并接受协议的全部条款。请您审慎阅读并选择接受或不接受协议（未成年人应在法定监护人陪同下阅读）。',langs)}}</p>
		<p><br>
		</p>
		<ol>
			<li class="title">{{getlang('用户使用：',langs)}}</li>
			<ol>
				<li>
					<p> {{getlang('用户按照页面给与的提示完成全部注册步骤后，即成为本软件的用户，用户应自行保管账号及密码，并准确、安全地使用其账号及密码。本软件可通过用户自行设定，向用户提供个性化信息服务。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('用户充分了解并同意，本软件为用户提供个性化信息服务，用户须为自己注册账号下的行为负责，包括用户所导入、上载、传送的任何内容以及由此产生的任何后果，用户应对本软件中的内容自行加以判断，并承担因使用内容而引起的所有风险。本软件不对因用户行为而导致的损失承担责任。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('用户须对在本软件上所传送信息的真实性、合法性、有效性等负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与本软件无关。用户在本软件中记录的或通过本软件服务所传送的任何内容并不反映本软件的观点或政策，本软件对此不承担责任。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('用户提供的个人信息或发布的信息不真实、不准确、不合法；发表内容不符合本协议或发表内容不符合法律法规的，本软件有权暂停或终止用户使用本平台的服务。用户如果对本软件的暂停或者终止或收回其账号行为有异议，客户可向平台提出意见，要求其继续提供服务。本软件收到客户此类信息的投诉与意见，会审核相关信息，并及时对用户给予反馈。若信息确实不真实或不准确或不合法有效，本软件平台要求用户提供符合要求的信息。',langs)}}</p>
				</li>
	
				<li>
					<p>{{getlang('为避免用户终端数据丢失、更好服务用户之目的，本软件将向注册用户提供数据备份服务，经注册用户授权，本软件将存储于注册用户终端的本软件数据库中的数据资料上载至本软件服务器，并且提供恢复到终端设备服务。',langs)}}
					</p>
				</li>
	
				<li>
					<h1>{{getlang('用户不得利用本软件服务制作、上载、复制、发布、传播或者转载如下内容：',langs)}}</h1>
				</li>
				<ol>
					<li>
						<h1>{{getlang('反对宪法所确定的基本原则的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('损害国家荣誉和利益的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('煽动民族仇恨、民族歧视，破坏民族团结的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('破坏国家宗教政策，宣扬邪教和封建迷信的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('散布谣言，扰乱社会秩序，破坏社会稳定的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('侮辱或者诽谤他人，侵害他人合法权益的；',langs)}}</h1>
					</li>
					<li>
						<h1>{{getlang('法律、行政法规禁止的其他内容。',langs)}}</h1>
						<br>
						<span class="text_indent">{{getlang('本软件有权针对用户发表的内容进行审核，若用户实施违反本条所述使用规则及法律法规的行为，本软件平台有权视行为严重程度，根据《侵权责任法》《信息网络传播权保护条例》等法律法规要求用户立即采取纠正、改正、恢复原状、消除影响等行为，且本软件有权自行采取合法有效且必要的措施（包括但不限于更改或删除相关侵权信息、暂停或终止用户使用本网络服务等），同时本软件保留进一步追究用户法律责任的权利。',langs)}}</span>
						<br>
						<span class="text_indent">{{getlang('同时，论坛社区版块发起者、管理者应当履行与本协议权利、法律法规相适应的义务，对违反法律规定和协议约定、履行责任义务不到位的服务提供者应当依法依约限制（更改或删除相关侵权信息、暂停使用）或取消其管理权限，直至封禁或者关闭有关账号、版块。',langs)}}</span>
					</li>
				</ol>
				<li>
					<p>{{getlang('用户禁止以文字、语音、图片等任何形式刻意宣传其他竞争平台；也禁止以相同的方式宣传非本软件联系方式，如果有聊天需求，可告知听众使用本软件私信联系。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('本软件的部分功能将产生资讯费用，具体资费标准以用户使用的电信运营商公布的价格为准，该部分费用应由用户自行承担。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('本软件提供的服务中可能包含广告，用户同意在使用过程中显示本软件和第三方供应商、合作伙伴提供的广告。用户因就第三方提供的服务、产品与第三方产生争议的，由用户与第三方自行解决，本软件不承担责任。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('本软件保留因业务发展需要，单方面对其服务器所提供的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销服务的权利。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('直播播主应尽量促进来访者的成长、自强自立；应避免负面结论而对用户造成消极的暗示， 或使用户在其未来的生活中对主播产生依赖。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('主播可通过听众送礼物获得星票。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('直播会消耗通讯设备电力，缩短设备使用时长；在直播过程中应做好充电准备，避免直播突然掉线影响直播效果。',langs)}}</p>
				</li>
			</ol>
	
	
			<li class="title">{{getlang('隐私政策：',langs)}}</li>
			<p class="text_indent">{{getlang('本软件尊重并保护所有使用服务用户的个人隐私权，高度重视用户的隐私及个人信息的保护，本隐私政策帮助您了解我们收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。',langs)}}</p>
			<p class="text_indent">{{getlang('我们将依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》以及其他相关法律法规、业界成熟的安全标准收集和本协议的约定使用您的个人信息，除本隐私权政策另有规定外，在未征得您事先许可的情况下，本软件不会将这些信息对外披露或向第三方提供。',langs)}}</p>
			<ol>
				<li>
					<p>{{getlang('收集和使用个人信息的范围。',langs)}}</p>
					<ol>
						<li>
							<p>{{getlang('注册账号：您注册并登录本软件账号时至少向我们提供账号名称、头像、手机号码或电子邮箱，并创建密码。注册成功后，您提供的上述信息，将在您使用本软件平台和服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息或对您的个人信息进行匿名化处理，法律法规另有规定的除外。',langs)}}</p>
						</li>
						<li>
							<p class="font_weight">{{getlang('同时使用手机号码注册成功后，我们可能收集如下信息：',langs)}}</p>
							<ol>
								<li>
									<p>{{getlang('设备信息：以便我们能在设备上为您提供服务，我们可能会将您的设备信息或电话号码与账号相关联，并收集设备属性信息、设备状态信息、设备链接信息。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('身份认证服务：本软件提供身份认证服务，当您申请成为本软件用户为您进行身份认证时我们需要收集您的出生日期、时间、出生地点有关身份证明的信息。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('信息发布功能：您注册成功后，可在本软件平台发布信息，对信息的内容，我们将不时进行审核，并根据法律规定对该内容进行保存。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('浏览、关注、收藏功能：您可浏览已关注或已加好友的发表的信息，我们将对你浏览、关注、收藏的记录信息进行审核，自动收集您使用本软件的详细情况，并根据法律规定对该内容进行保存。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('展示和推送内容：通过使用收集的信息，我们会向您提供搜索结果、个人化内容、用户研究分析与统计等服务。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('搜索功能：当您使用本软件提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用本软件服务时所阅览或要求的其他信息和内容详情。该等关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本指引的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本指引对其进行处理与保护。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('日历权限：用于让用户在日历里看到个性化运势。我们也可能通过通过您的提供个人信息，向您提供您感兴趣的信息。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('客户服务：当你向本软件提起投诉、建议、咨询或本软件解决相关投诉问题时，为方便与您联系，我们可能会使用您提供的姓名、手机号码、电子邮箱及其他等个人信息。',langs)}}
										{{getlang('如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。',langs)}}</p>
								</li>
							</ol>
						</li>
						<li>
							<p class="font_weight">{{getlang('向您提供本软件产品和/或服务的附加业务功能',langs)}}</p>
							<p>{{getlang('为了向您提供更优质的产品和服务，我们可能需要收集下述信息。 如果您拒绝提供下述信息，不影响您正常使用本条1.2项所描述的本软件业务功能，但我们无法向您提供某些特定功能和服务。',langs)}}</p>
							<ol>
								<li>
									<p>{{getlang('定位功能：GPS地址和位置信息，将用于更方便的计算星座信息；当您开启设备定位功能并使用本软件基于位置提供的相关服务时，我们会收集有关您的位置信息。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('麦克风权限、蓝牙权限、摄像头权限：主要用户收听或使用语音服务，认证过程中的面部识别。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('相册权限：用户可使用app默认头像，如用户自主选择头像，我们将调取相册权限，获悉用户头像选择、更换的头像。',langs)}}</p>
								</li>
							</ol>
						</li>
						<li>
							<p>{{getlang('我们可能从第三方间接获取您的个人信息',langs)}}</p>
							<p>{{getlang('从第三方间接获取您的个人信息：您使用第三方账号登录本软件时，已授权本软件获得您的登记、公开的信息。您在该第三方平台上登记、公布、记录的公开信息（包括昵称、头像）。',langs)}}</p>
						</li>
						<li>
							<p>{{getlang('其他用途：当我们要将信息用于本隐私政策未载明的其他用途，将会事先征求您的同意。',langs)}}</p>
							<p>{{getlang('按照法律法规要求，下列如下情况我们将无法满足您的上述请求：',langs)}}</p>
							<ol>
								<li>
									<p>{{getlang('与国家安全、国家利益相关的；',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('与公共安全、公共利益相关的；',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('与您或者第三方重大利益相关并可能会导致严重损害的；',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('与犯罪、司法程序、政府程序等直接相关的；',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('涉及商业秘密的;',langs)}}</p>
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					<p>{{getlang('我们如何保护您的个人信息',langs)}}</p>
					<p class="text_indent">{{getlang('为防止您的信息丢失、未经您同意授权的访问、公开披露、泄露、转让等导致您个人信息的受损，本软件平台将采取一切合理、可靠、可行的方案与措施，保障您的个人信息安全。',langs)}}</p>
					<ol>
						<li>
							<p>{{getlang('安全措施：',langs)}}</p>
							<ol>
								<li>
									<p>{{getlang('我们将以国家安全标准和法律法规的规定收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。同时，我们会对个人信息进行加密技术，以确保数据的保密性与安全性。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('对员工信息接触者签署保密协议，定期进行安全能力与意识的培训。专岗专职，只有授权员工方可访问个人信息，若违反保密协议，将追究员工相关法律责任。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('严格选择合作伙伴，对其进行背景调查，与有知名度且信誉良好的企业合作；与合作伙伴信息接触者签署保密协议，约定泄密的违约责任，并仅提供必要合理的信息。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('成立安全团队，安全团队负责研发和应用安全技术和程序，以确保本软件平台及个人信息的安全。我们将对安全团队负责人和信息技术负责人进行背景调查，签署保密协议，约定泄密的法律责任，并持续对其进行安全能力与意识的培训。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('我们建立完善的信息安全管理制度和内部安全事件处置机制等。',langs)}}</p>
								</li>
							</ol>
						</li>
						<li>
							<p>{{getlang('保存期限',langs)}}</p>
							<p class="text_indent">{{getlang('您在使用本软件产品及服务期间，我们将持续为您保存您的个人信息。如果您将个人信息修改，我们会保存修改后的信息。',langs)}}</p>
							<p class="text_indent">{{getlang('如果您注销帐户后，我们不会再对您的个人信息进行使用，我们将依据网络安全法等法律法规规定保存您的信息并进行匿名化处理。',langs)}}</p>
						</li>
						<li>
							<p>{{getlang('安全事件通知',langs)}}</p>
							<ol>
								<li>
									<p>{{getlang('我们会预先制定互联网安全事件预警方案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('若发生个人信息安全事件，我们将通过您预留的个人信息（包含手机号码、电子邮箱等）及时通知您，并告知您案件进展和影响，我们将联合安全团队、法务部、技术部共同采取积极有效的处理手段，采取补救措施以降低风险。同时，我们将根据有关政府部门及法律法规要求，主动上报安全事件的情况，并可能采取法律手段解决安全事件。',langs)}}
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p>{{getlang('如果决定更改隐私政策，我们会在本政策中及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。',langs)}}</p>
						</li>
					</ol>
				</li>
	
				<li>
					<p>{{getlang('您管理个人信息的权利',langs)}}</p>
					<p class="text_indent">{{getlang('我们非常重视您对个人信息的关注，并尽全力保护您对于自己个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。 您的权利包括：',langs)}}</p>
					<ol>
						<li>
							<p>{{getlang('访问和更正您的个人信息',langs)}}</p>
							<p>{{getlang('A. 除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：您可通过【我】 ，访问或者修改您的邮箱信息、密码、用户名；',langs)}}</p>
						</li>
						<li>
							<p>{{getlang('删除您的个人信息',langs)}}</p>
							<ol>
								<li>
									<p>{{getlang('我们在本软件 App 上提供以下方式帮助您删除您在本软件上发布的信息。 您可通过【社区→我的帖子】，删除您在社区中展示的信息； 您可通过【我的 → 关注】，取消您的关注记录；',langs)}}
										{{getlang('您可通过【我的 →设置】，取消运势提醒；',langs)}}
										{{getlang('您可通过【我的 →设置】，取消私信开关。',langs)}}</p>
								</li>
								<li>
									<p>{{getlang('在以下情形中，您可以向我们提出删除个人信息的请求：',langs)}}<br>
										{{getlang('如果我们处理个人信息的行为违反法律法规；',langs)}}<br>
										{{getlang('如果我们收集、使用您的个人信息，却未征得您的同意；',langs)}}<br>
										{{getlang('如果我们处理个人信息的行为违反了与您的约定；',langs)}}<br>
										{{getlang('如果您注销了本软件帐号；',langs)}}<br>
										{{getlang('如果我们终止服务及运营。',langs)}}</p>
									<p>
										{{getlang('以上删除请求一旦被响应，我们还将同时通知从本软件获得您个人信息的第三方实体（例如使用本软件帐号作为第三方登录账号的其他互联网平台），要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。',langs)}}
									</p>
								</li>
							</ol>
	
						</li>
					</ol>
	
	
				</li>
			</ol>
			<li class="title">{{getlang('未成年人个人信息：',langs)}}</li>
			<p class="text_indent">{{getlang('若您是未成年人，建议您的监护人仔细阅读本隐私保护条例的条款，并在征得您的监护人同意的前提下使用我们的产品和服务或向我们提供信息。',langs)}}</p>
			<p class="text_indent">{{getlang('如您的监护人不同意您使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。',langs)}}</p>
			<p class="text_indent">{{getlang('如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请监护人反馈联系我们，我们在核准相关情况后尽快删除您的个人数据。',langs)}}</p>
	
			<li class="title font_weight">{{getlang('法律责任与免责：',langs)}}</li>
			<ol>
				<li>
					<p class="font_weight">{{getlang('本软件平台对平台进行停机维护、定期检查、更新软硬件、针对突发事件、不可抗力、电脑病毒、系统故障等因素导致的正常服务中断、中止，本软件不承担责任，本软件平台将尽力避免服务中断并将中断时间限制在最短时间内。',langs)}}</p>
				</li>
				<li>
					<p class="font_weight">{{getlang('用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，本软件不承担责任。',langs)}}</p>
				</li>
				<li>
					<p class="font_weight">{{getlang('因用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方向本软件主张的索赔，由用户自行承担。',langs)}}</p>
				</li>
				<li>
					<p class="font_weight">{{getlang('用户在本软件平台发表的观点及立场，并不代表本软件的立场，用户应自行对发表内容负责。',langs)}}</p>
				</li>
				<li>
					<p class="font_weight">{{getlang('请客户自行妥善保管个人资料，将个人信息提供足够保障及备份，并采取适当的预防措施降低电脑病毒或其他恶意破坏性举动的风险。',langs)}}</p>
				</li>
				<li>
					<p class="font_weight">{{getlang('由于您的隐私权对本网站相当重要，除非有权机关依法调取或者第三条约定，本软件平台不会无故把您的个人信息提供给无关的第三方，但不对第三人恶意侵权导致信息泄露而产生的后果负责。',langs)}}</p>
				</li>
			</ol>
	
			<li class="title">{{getlang('知识产权：',langs)}}</li>
			<ol>
				<li>
					<p>{{getlang('本软件的一切知识产权，以及与软件相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图像、图表、色彩、界面设计、版面框架、有关数据、附加程序、印刷材料或电子文档等均归本软件所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('未经本软件书面同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权。出现上述未经许可之行为时，本软件保留追究相关责任人法律责任之权利。',langs)}}</p>
				</li>
			</ol>
	
	
			<li class="title">{{getlang('修改与解释权：',langs)}}</li>
			<p class="text_indent">{{getlang('根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，本软件有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，您可在本软件官方网站查阅最新版协议条款，该公布行为视为本软件已经通知用户修改内容，而不另行对用户进行个别通知。在本软件修改协议条款后，如果您不接受修改后的条款，请立即停止使用本软件提供的服务，您继续使用本软件提供的服务将被视为已接受了修改后的协议。',langs)}}</p>
	
			<li class="title">{{getlang('通知',langs)}}</li>
			<p class="text_indent">{{getlang('您在注册成为本软件用户，并接受本软件服务时，您应该本软件提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。',langs)}}</p>
			<p class="text_indent">{{getlang('本软件将向您的上述联系方式的其中之一或其中若干向您送达各类通知。平台信息发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。',langs)}}</p>
			<li class="title">{{getlang('法律适用、管辖与其他：',langs)}}</li>
			<ol>
				<li>
					<p>{{getlang('本软件郑重提醒用户注意本协议中可能存在的免除本软件责任和加重用户义务的条款，请用户仔细阅读，自主考虑风险。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例或行业惯例。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('与本协议、使用本软件发生的任何争议或纠纷，双方应尽量友好协商解决；协商不成时，任何一方均有权将纠纷交由北京市海淀区人民法院管辖。',langs)}}</p>
				</li>
				<li>
					<p>{{getlang('本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。',langs)}} </p>
				</li>
			</ol>
		</ol>
	</div>
</template>

<script>

export default {
    data(){
        return{
            num:0,
			langs:localStorage.getItem('lang')
        }
    }
}
</script>

<style>
	body {
		background-color: #fff;
		font: 13px/20px normal Helvetica, Arial, sans-serif;
		color: #4F5155;
        
	}

	a {
		color: #003399;
		background-color: transparent;
		font-weight: normal;
	}

	h1 {
		color: #444;
		background-color: transparent;
		border-bottom: 1px solid #D0D0D0;
		font-size: 16px;
		font-weight: normal;
		margin: 0 0 14px 0;
		padding: 14px 15px 10px 15px;
	}

	.title {
		font-size: 14px;
		font-weight: bolder;
	}

	code {
		font-family: Consolas, Monaco, Courier New, Courier, monospace;
		font-size: 12px;
		background-color: #f9f9f9;
		border: 1px solid #D0D0D0;
		color: #002166;
		display: block;
		margin: 14px 0 14px 0;
		padding: 12px 10px 12px 10px;
	}

	#container {
		margin: 0;
		box-shadow: 0 0 8px #D0D0D0;
		-webkit-box-shadow: 0 0 8px #D0D0D0;
		-moz-box-shadow: 0 0 8px #D0D0D0;
		padding-bottom: 12px;
        text-align: left !important;
	}

	p {
		margin: 12px 15px 12px 15px;
	}

	.p_left {
		margin: 0px;
	}

	.p_left_k {
		margin-left: -13px;
	}

	.p_width {
		font-weight: 800;
	}

	.p_mleft {
		margin-left: -14px;
	}

	.text_indent {
		text-indent: 5em;
		margin-left: 0;
	}

	.font_weight {
		font-weight: bold;
	}

	span.text_indent {
		display: block;
	}

	ol {
		display: block;
		list-style-type: decimal;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding:.5rem;
	}

	li {
		display: list-item;
		text-align: -webkit-match-parent;
	}

	#hadsky-tools {
		display: none;
	}
</style>